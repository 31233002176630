
const IMAGES = {
	"encabezado": new URL("../img/encabezado.webp", import.meta.url),
	"take_a_breath.m": new URL("../img/2019.take_a_breath.m.webp", import.meta.url),
	"take_a_breath.l": new URL("../img/2019.take_a_breath.l.webp", import.meta.url),
	"blue_cup.m": new URL("../img/2021.blue_cup.m.webp", import.meta.url),
	"blue_cup.l": new URL("../img/2021.blue_cup.l.webp", import.meta.url),
	"cyano_blues.m": new URL("../img/2021.cyano_blues.m.webp", import.meta.url),
	"cyano_blues.l": new URL("../img/2021.cyano_blues.l.webp", import.meta.url),
	"collage_bergara.m": new URL("../img/2022.collage_bergara.m.webp", import.meta.url),
	"collage_bergara.l": new URL("../img/2022.collage_bergara.l.webp", import.meta.url),
	"collage_malaga.m": new URL("../img/2022.collage_malaga.m.webp", import.meta.url),
	"collage_malaga.l": new URL("../img/2022.collage_malaga.l.webp", import.meta.url),
};

export default IMAGES;
