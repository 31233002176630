import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Image } from "../../state";
import IMAGES from "../../images";
import "./image-cards.css";

interface ImageCardProps {
	image: Image;
	onClick?: (id: Image) => void;
	titleSupplier?: (image: Image) => string;
}

function ImageCard(props: ImageCardProps) {

	const image = props.image;
	const text = (props.titleSupplier != undefined) ? props.titleSupplier(image) : image.text;
	return <Col>
		<Card className="card" onClick={() => props.onClick(image)}>
			<Card.Img variant="top" src={IMAGES[image.media.MEDIUM].href} />
		</Card>
	</Col>;
}
type ImageCardsProps = {
	images: Image[];
	title?: string;
	onClick?: (id: Image) => void;
	titleSupplier?: (image: Image) => string;
}

export default function ImageCards(props: ImageCardsProps) {

	const images = props.images;
	if (images.length === 0) {
		return <></>;
	}
	const title = props.title;
	const titleRow = title? <Row><Col className="title">{title}</Col></Row> : <></>;

	return <Container fluid>
		{ titleRow }
		<Row sm={1} md={2} lg={3} xl={3}>
			{images.map(image => <ImageCard image={image} key={image.id} onClick={props.onClick} titleSupplier={props.titleSupplier} />)}
		</Row>
	</Container>;
}
