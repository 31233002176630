import ImageCards from "./image-cards/image-cards";
import ImageModal from "./image-modal/image-modal";
import { selectPainting, Image, useAppDispatch, useAppSelector } from "../state";

export default function Gallery() {

	const selectedCollection = useAppSelector((state) => state.album.selectedCollection);
	const images = useAppSelector((state) => state.album.images);
	const appDispatch = useAppDispatch();
	const collectionImages = images.filter(value => value.collection.id === selectedCollection.id);

	const onClick = (image: Image) => {
		appDispatch(selectPainting(image));
	};

	return <>
		<ImageCards images={collectionImages} title={selectedCollection.description} onClick={onClick} />
		<ImageModal />
	</>;
};
