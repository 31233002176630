import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from "./navigation/navigation";
import albumReducer from "./album/album";

export const store = configureStore({
	reducer: {
		navigation: navigationReducer,
		album: albumReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
