import { Collection } from "./collection";

export enum PaintingType {
	MEDIUM,
	LARGE,
};

export interface Image {
	id: number,
	text: string;
	collection: Collection;
	media: {
		MEDIUM: string,
		LARGE: string,
	};
}
