import { Modal } from "react-bootstrap";
import { deselectPainting, useAppDispatch, useAppSelector } from "../../state";
import IMAGES from "../../images";
import "./image-modal.css";

export default function ImageModal() {

	const selectedImage = useAppSelector((state) => state.album.selectedImage);
	const appDispatch = useAppDispatch();
    const show = (selectedImage != null);

    function hide() {
        appDispatch(deselectPainting());
    }

    return <>
        <Modal show={show} fullscreen onHide={() => hide()}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body><div><img src={IMAGES[selectedImage?.media.LARGE]?.href} /></div></Modal.Body>
        </Modal>
    </>;
}
