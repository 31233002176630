import { createRoot } from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/lato"; // Defaults to weight 400
import "../css/main.css"
import { Provider } from "react-redux";
import { store, useAppSelector, Navigation } from "./state";
import Home from "./components/home";
import Gallery from "./components/gallery";
import Header from "./components/header/header";

const home = document.getElementById("home");

function Page() {
	const location = useAppSelector((state) => state.navigation.location);
	const page = (() => {
			switch (location) {
			case Navigation.Home:
				return <Home />
			case Navigation.Gallery:
				return <Gallery />
			default:
				return <></>;
		};
	})();
	return page;
}

createRoot(home).render(
	<Provider store={store}>
		<>
			<Header />
			<Page />
			<p></p>
		</>
	</Provider>
);
