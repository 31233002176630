import Carousel from "react-bootstrap/Carousel";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useAppDispatch, useAppSelector, Navigation, toGallery, toHome, selectCollection, Collection } from "../../state";
import IMAGES from "../../images";
import "./header.css";

function ImageSlider() {

	const imageIds = [1, 2, 3, 4, 5, 6];
	const carouselItems = imageIds.map(id => {
		const backgroundImage = { backgroundImage: `url(${IMAGES[id].href})` };
		return <Carousel.Item key={id}>
			<div className="imageSliderItem" style={backgroundImage}></div>
		</Carousel.Item>;
	});

	return <Carousel fade indicators={false} controls={false}>
		{carouselItems}
	</Carousel>;
}

function NavigationBar() {

	const location = useAppSelector((state) => state.navigation.location);
	const collections = useAppSelector((state) => state.album.collections);
	const appDispatch = useAppDispatch();
	const galleryItems = collections.map(collection =>
		<NavDropdown.Item key={collection.id} href="#" onClick={() => goToGallery(collection)}>{collection.description}</NavDropdown.Item>
	);

	function goToHome() {
		appDispatch(toHome());
	}
	function goToGallery(collection: Collection) {
		appDispatch(selectCollection(collection));
		appDispatch(toGallery());
	}

	return <Navbar expand="sm" variant="light" className="py-3">
		<div>
			<Navbar.Brand href="#" onClick={goToHome}>Raquel Alvarez</Navbar.Brand>
			<br />Mixed Media Fine Art
		</div>
		<Navbar.Toggle aria-controls="basic-navbar-nav" />
		<Navbar.Collapse id="basic-navbar-nav" role="navigation">
			<Nav className="ms-auto">
				<Nav.Link active={location === Navigation.Home} onClick={goToHome}>Inicio</Nav.Link>
				<NavDropdown title="Galería" align="end">
					{galleryItems}
				</NavDropdown>
			</Nav>
		</Navbar.Collapse>
	</Navbar>;
}

export default function Header() {

	const style = {padding: "1rem", margin: 0};
	const backgroundImage = { backgroundImage: `url(${IMAGES["encabezado"].href})`, color: "white", padding: "6rem 0 6rem 4rem" };
	return <>
		<h4 style={style}>Raquel Fine Art</h4>
		<div style={backgroundImage}>
			<span style={{fontSize: "4rem"}}>Raquel Alvarez</span>
			<br />
			<span style={{fontSize: "1.5rem"}}>mixed media art/collage & painting</span>
		</div>
	</>;
}
