import { createSlice } from "@reduxjs/toolkit";


export const enum Navigation {
	Gallery,
	Home,
};

interface NavigationState {
	location: Navigation,
}

const initialState: NavigationState = {
	location: Navigation.Home,
};

const navigationSlice = createSlice({
	name: "navigation",
	initialState,
	reducers: {
		toHome(state) {
			state.location = Navigation.Home;
		},
		toGallery(state) {
			state.location = Navigation.Gallery;
		},
	},
});

export const { toHome, toGallery } = navigationSlice.actions;
export default navigationSlice.reducer;
