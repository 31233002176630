import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Collection } from "./collection";
import { Image } from "./image";

const collections: Collection[] = [
    { id: 0, description: "Estaciones" },
    { id: 1, description: "Collages pequeños en cartulina negra con flores" },
    { id: 2, description: "Galería" },
];

const images: Image[] = [
    { id: 1, text: "", collection: collections[2], media: { MEDIUM: "take_a_breath.m", LARGE: "take_a_breath.l"} },
    { id: 2, text: "", collection: collections[2], media: { MEDIUM: "blue_cup.m", LARGE: "blue_cup.l"} },
    { id: 3, text: "", collection: collections[2], media: { MEDIUM: "cyano_blues.m", LARGE: "cyano_blues.l"} },
    { id: 4, text: "", collection: collections[2], media: { MEDIUM: "collage_bergara.m", LARGE: "collage_bergara.l"} },
    { id: 5, text: "", collection: collections[2], media: { MEDIUM: "collage_malaga.m", LARGE: "collage_malaga.l"} },
];

interface AlbumState {
    collections: Collection[],
    images: Image[],
    selectedCollection: Collection,
    selectedImage: Image,
};

const initialState: AlbumState = {
    collections: collections,
    images,
    selectedCollection: null,
    selectedImage: null,
};

const albumSlice = createSlice({
    name: "album",
    initialState,
    reducers: {
        selectPainting(state, action: PayloadAction<Image>) {
            state.selectedImage = action.payload;
        },
        deselectPainting(state) {
            state.selectedImage = null;
        },
        selectCollection(state, action: PayloadAction<Collection>) {
            state.selectedCollection = action.payload;
        },
        deselectCollection(state) {
            state.selectedCollection = null;
        },
    },
});

export const { selectCollection, selectPainting, deselectCollection, deselectPainting } = albumSlice.actions;
export default albumSlice.reducer;
