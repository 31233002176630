import { Collection, Image, useAppDispatch, useAppSelector, toGallery, selectPainting, selectCollection } from "../state";
import ImageCards from "./image-cards/image-cards";
import ImageModal from "./image-modal/image-modal";

export default function Home() {

	const collections = useAppSelector((state) => state.album.collections);
	const images = useAppSelector((state) => state.album.images);
	const appDispatch = useAppDispatch();

	function random<T>(items: Array<T>): T {
		const item = items[Math.floor(Math.random() * items.length)];
		return item;
	}

	const collectionImages = images.filter(value => value.collection.id === 2);

	function goToGallery(image: Image) {
		appDispatch(selectCollection(image.collection));
		appDispatch(toGallery());
	}

	const goToPainting = (image: Image) => {
		appDispatch(selectPainting(image));
	};

	function collectionTitle(image: Image) {
		return image.text;
	}

	return <>
		<ImageCards images={collectionImages} title="Galería" onClick={goToPainting} titleSupplier={collectionTitle} />
		<ImageModal />
	</>;
};
